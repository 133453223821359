import { ActionContext } from 'vuex';
import { PlatonContractState } from '@/stores/types.ts';
import { PlatonNewBasicForm } from '@/views/platon/PlatonNewBasic/Form';
import { PlatonNewOptionsData } from '@/views/platon/PlatonNewOptions/PlatonNewOptionsForm';
import { PlatonDivision } from '@/domain/PlatonContract/Types';
import {
  defaultPlatonContract,
  PlatonContract,
} from '@/domain/PlatonContract/PlatonContract';
import * as platonApi from '@/api/platon';

const state: PlatonContractState = {
  model: defaultPlatonContract,
  endUserKeywords: '',
  salesAgentKeywords: '',
};

const getters = {
  contractLabel(st: PlatonContractState): string {
    if (st.model.plan.division === PlatonDivision.Otegaru) {
      return 'お手軽Platon';
    }
    if (st.model.plan.division === PlatonDivision.OnPremise) {
      return 'オンプレPlaton';
    }
    return 'Platon';
  },
};

const actions = {
  updateModel(
    context: ActionContext<PlatonContractState, {}>,
    model: PlatonContract
  ) {
    context.commit('updateModel', model);
  },
  updateSearchKeywords(
    context: ActionContext<PlatonContractState, {}>,
    keywords: {
      endUserName: string;
      salesAgentName: string;
    }
  ) {
    context.commit('updateSearchKeywords', keywords);
  },
  updateBasic(
    context: ActionContext<PlatonContractState, {}>,
    form: PlatonNewBasicForm
  ) {
    context.commit('updateBasic', form);
  },
  updateOptions(
    context: ActionContext<PlatonContractState, {}>,
    form: PlatonNewOptionsData
  ) {
    context.commit('updateOptions', form);
  },
  async submit(context: ActionContext<PlatonContractState, {}>, token: string) {
    if (context.state.model.plan.division === PlatonDivision.Otegaru) {
      await platonApi.addOtegaru(token, context.state.model);
      context.commit('reset');
    }
    if (context.state.model.plan.division === PlatonDivision.OnPremise) {
      await platonApi.addOnPremise(token, context.state.model);
      context.commit('reset');
    }
  },
};

const mutations = {
  updateModel(st: PlatonContractState, model: PlatonContract) {
    st.model = model;
    st.model.closingDay = model.closingDay === 0 ? 24 : model.closingDay;
    if (!st.model.options) st.model.options = [];
  },
  updateSearchKeywords(
    st: PlatonContractState,
    keywords: { endUserName: string; salesAgentName: string }
  ) {
    st.endUserKeywords = keywords.endUserName;
    st.salesAgentKeywords = keywords.salesAgentName;
  },
  updateBasic(st: PlatonContractState, form: PlatonNewBasicForm) {
    st.model.plan.division = form.division;
    st.model.endUserId = parseInt(form.endUserId, 10);
    st.model.salesAgentId = parseInt(form.salesAgentId, 10);
    st.model.planId = form.plan.planId;
    st.model.plan.planId = form.plan.planId;
    st.model.planData = form.plan.planData;
    st.model.url = form.url;
    st.model.siteId = form.siteId;
    st.model.startDate = form.startDate + 'T00:00:00Z';
    st.model.billingStartDate = form.billingStartDate + 'T00:00:00Z';
    st.model.closingDay = form.closingDay === 0 ? 24 : form.closingDay;
    st.model.version.versionLabel = form.initialVersion;
    st.model.version.updateDate = form.startDate + 'T00:00:00Z';
    st.model.salesMemo = form.salesMemo;

    st.endUserKeywords = form.endUserKeywords;
    st.salesAgentKeywords = form.salesAgentKeywords;

    st.model.flowrateUpperLimit = form.plan.flowrateUpperLimit;
    st.model.capacityUpperLimit = form.plan.capacityUpperLimit;
  },

  updateOptions(st: PlatonContractState, form: PlatonNewOptionsData) {
    st.model.options = [];
    form.optionData.forEach(opt => {
      if (!opt.data.using) return;
      opt.startDate =
        opt.data.startDate && opt.data.startDate != ''
          ? opt.data.startDate + 'T00:00:00Z'
          : st.model.startDate;
      opt.endDate = opt.data.endDate ? opt.data.endDate + 'T00:00:00Z' : null;
      st.model.options.push(opt);
    });
  },

  reset(st: PlatonContractState) {
    st.model = defaultPlatonContract;
    st.endUserKeywords = '';
    st.salesAgentKeywords = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
