import { LibraContract } from '@/domain/LibraContract/LibraContract';
import { Server } from '@/domain/LibraContract/Server';
import { LibraDetailForm } from './LibraDetailForm';

// モデルから契約詳細フォームへの変換
export const model2form = (obj: LibraContract): LibraDetailForm => {
  const serverModel = obj.server || new Server();
  const serverForm = {
    infrastructure: serverModel.infrastructure,
    os: serverModel.os,
    ipAddress: serverModel.ipAddress,
    serverDetailUrl: serverModel.serverDetailUrl,
    installedPerson: serverModel.installedPerson,
    libraInstallDirectory: serverModel.libraInstallDirectory,
    installedDate: serverModel.installedDate
      ? serverModel.installedDate.substr(0, 10)
      : '',
  };
  if (obj.options) {
    obj.options.map(opt => {
      opt.data = JSON.parse(String(opt.data));
      opt.data['using'] = true;
      opt.mode = 'edit';
      return opt;
    });
  }

  return {
    libraId: obj.libraId,
    endUserName: obj.endUser.customerName,
    salesAgentId: obj.salesAgent ? obj.salesAgent.customerId.toString() : '0',
    plan: {
      planId: obj.planId,
      planData: obj.planData,
      flowrateUpperLimit: obj.flowrateUpperLimit,
      capacityUpperLimit: obj.capacityUpperLimit,
      otegaruPlanType: obj.otegaruPlanType,
    },
    url: obj.url,
    siteId: obj.siteId,
    startDate: obj.startDate.substr(0, 10),
    billingStartDate: obj.billingStartDate
      ? obj.billingStartDate.substr(0, 10)
      : obj.startDate.substr(0, 10), // オンプレはbillingStartDateがないので、interface統一のためにstartDateを入れておく
    endDate: obj.endDate ? obj.endDate.substr(0, 10) : '',
    closingDay: obj.closingDay,
    salesMemo: obj.salesMemo,
    server: serverForm,
    options: obj.options,
    accounts: obj.accounts,
    salesAgentKeywords: obj.salesAgent ? obj.salesAgent.customerName : '',
  };
};
