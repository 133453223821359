import { Customer } from '../Customer';
import { Plan } from './Plan';
import { Server } from './Server';
import { PlatonOption } from './PlatonOption';
import { PlatonAccount } from './PlatonAccount';
import { PlatonVersion } from './PlatonVersion';
import { PlatonInstaller } from './PlatonInstaller';

export interface PlatonContract {
  platonId: number;
  endUserId: number;
  endUser: Customer;
  endUserName: string;
  salesAgentId: number;
  salesAgent: Customer | null;
  planId: number;
  plan: Plan;
  planData: string;
  url: string;
  siteId: string;
  startDate: string;
  billingStartDate: string;
  endDate: string | null;
  closingDay: number;
  timezone: string;
  salesMemo: string;
  server: Server | null;
  options: PlatonOption[];
  accounts: PlatonAccount[];
  version: PlatonVersion;
  installer: PlatonInstaller;
  flowrateUpperLimit: number;
  capacityUpperLimit: number;
}

export const defaultPlatonContract = {
  platonId: 0,
  endUserId: 0,
  endUser: new Customer(),
  endUserName: '',
  salesAgentId: 0,
  salesAgent: new Customer(),
  planId: 0,
  plan: new Plan(),
  planData: '',
  url: 'https://',
  siteId: '',
  startDate: '',
  billingStartDate: '',
  endDate: null,
  closingDay: 99,
  timezone: 'Asia/Tokyo',
  salesMemo: '',
  server: new Server(),
  options: [] as PlatonOption[],
  accounts: [] as PlatonAccount[],
  version: new PlatonVersion(),
  installer: new PlatonInstaller(),
  flowrateUpperLimit: 0,
  capacityUpperLimit: 0,
};
