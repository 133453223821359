




















































































































import { Vue } from 'vue-property-decorator';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Component from 'vue-class-component';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import Select from '@/components/molecules/Select.vue';
import TextField from '@/components/molecules/TextField.vue';
import Navigation from '@/components/Navigation.vue';
import CustomerSearchSelector from '@/components/customer/CustomerSearchSelector.vue';
import PlatonPlanSelector from '@/components/platon/PlatonPlanSelector.vue';
import { PlatonDivision } from '@/domain/PlatonContract/Types';
import { PlatonContract } from '@/domain/PlatonContract/PlatonContract';
import { closingDays } from '@/domain/ClosingDay';
import { otegaruPlans, onpremisePlans } from '@/domain/PlatonContract/Plan';
import {
  defaultPlatonNewBasicForm,
  PlatonNewBasicForm,
} from './PlatonNewBasic/Form';
import * as customerApi from '@/api/customer';
import * as sessionStore from '@/stores/session';
import { Customer } from '@/domain/Customer';

@Component({
  components: {
    ValidationObserver,
    ActionMenu,
    DatePicker,
    Select,
    TextField,
    Navigation,
    CustomerSearchSelector,
    PlatonPlanSelector,
  },
  computed: {
    ...mapState('platonNew', [
      'model',
      'endUserKeywords',
      'salesAgentKeywords',
    ]),
    ...mapGetters('platonNew', ['contractLabel']),
  },
  methods: {
    ...mapMutations('platonNew', ['updateBasic']),
    ...mapActions('alertArea', ['success', 'fail']),
  },
})
export default class PlatonNewBasic extends Vue {
  /**
   * mapping
   */
  private model!: PlatonContract;
  private endUserKeywords!: string;
  private salesAgentKeywords!: string;
  private updateBasic!: (vm: PlatonNewBasicForm) => void;
  private contractLabel!: () => string;
  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  private endusers: Customer[] = [];
  private salesAgents: Customer[] = [];

  private actionMenu = [
    {
      type: 'button',
      icon: 'arrow_back',
      text: '契約一覧に戻る',
      color: '',
      click: () => {
        this.clickReturn();
      },
    },
    {
      type: 'submit',
      icon: 'arrow_forward',
      text: '契約オプション選択へ',
      color: 'primary',
      click: () => {
        // only submit
      },
    },
  ];

  private get headerClass(): string {
    let value = 'blue pa-4 mr-1 white--text';
    if (this.vm.division === PlatonDivision.OnPremise) {
      value += ' darken-4';
    }
    return value;
  }

  private get plans() {
    if (this.vm.division === PlatonDivision.Otegaru) {
      return otegaruPlans;
    }
    if (this.vm.division === PlatonDivision.OnPremise) {
      return onpremisePlans;
    }
    // undefined
    return [];
  }

  private get closingDays() {
    return closingDays;
  }

  private vm = defaultPlatonNewBasicForm;

  private created() {
    const division = this.$route.params.division;
    if (division === 'otegaru') {
      this.model.plan.division = PlatonDivision.Otegaru;
    } else {
      this.model.plan.division = PlatonDivision.OnPremise;
    }

    const plan = {
      planId: this.model.planId,
      planData: this.model.planData,
      flowrateUpperLimit: this.model.flowrateUpperLimit,
      capacityUpperLimit: this.model.capacityUpperLimit,
    };

    this.vm = {
      division: this.model.plan.division,
      endUserId:
        this.model.endUserId === 0 ? '' : this.model.endUserId.toString(),
      salesAgentId: this.model.salesAgentId.toString(),
      plan: plan,
      url: this.model.url,
      siteId: this.model.siteId,
      startDate:
        this.model.startDate.length === '2006-01-02T00:00:00Z'.length
          ? this.model.startDate.substr(0, 10)
          : '',
      billingStartDate:
        this.model.billingStartDate.length === '2006-01-02T00:00:00Z'.length
          ? this.model.billingStartDate.substr(0, 10)
          : '',
      closingDay: this.model.closingDay,
      initialVersion: this.model.version.versionLabel,
      salesMemo: this.model.salesMemo,
      endUserKeywords: this.endUserKeywords,
      salesAgentKeywords: this.salesAgentKeywords,
    };
    this.searchEndUsers(this.vm.endUserKeywords);
    this.searchSalesAgents(this.vm.salesAgentKeywords);
  }

  /**
   * プロパティ
   */
  private get PlatonDivision() {
    return PlatonDivision;
  }

  /**
   * 操作
   */
  private async searchEndUsers(keywords: string) {
    try {
      this.endusers = await customerApi.list(
        sessionStore.getToken(),
        5,
        0,
        keywords
      );
    } catch {
      this.fail('顧客一覧(エンドユーザー)の取得に失敗しました');
      return;
    }
  }

  private async searchSalesAgents(keywords: string) {
    try {
      this.salesAgents = await customerApi.list(
        sessionStore.getToken(),
        5,
        0,
        keywords
      );
    } catch {
      this.fail('顧客一覧(代理店)の取得に失敗しました');
      return;
    }
    this.salesAgents.push(new Customer(0, '代理店を指定しない'));
  }

  private updateEndUserKeywords(keywords: string) {
    this.vm.endUserKeywords = keywords;
    this.update();
  }
  private updateSalesAgentKeywords(keywords: string) {
    this.vm.salesAgentKeywords = keywords;
    this.update();
  }
  private update() {
    this.updateBasic(this.vm);
  }

  private async addCustomer(keywords: string) {
    if (keywords === '') {
      this.fail('顧客名が入力されていません');
      return;
    }

    const customer = new Customer(0, keywords);
    try {
      await customerApi.add(sessionStore.getToken(), customer);
      this.success('顧客を追加しました');
    } catch (e) {
      this.fail(e.response.data.message);
    }

    this.searchEndUsers(this.vm.endUserKeywords);
    this.searchSalesAgents(this.vm.salesAgentKeywords);
  }

  private openOption(validate: any) {
    validate().then((result: any) => {
      if (!result.isValid) {
        return;
      }
      this.$router.push('options');
    });
  }

  private clickReturn() {
    this.$router.push('../list');
  }
}
