import { Account } from '@/components/platon/Account';
import { PlatonOption } from '@/domain/PlatonContract/PlatonOption';

export interface PlatonDetailForm {
  platonId: number;
  endUserName: string;
  salesAgentId: string;
  plan: Plan;
  url: string;
  siteId: string;
  startDate: string;
  billingStartDate: string;
  endDate: string;
  closingDay: number;
  salesMemo: string;
  server: Server;
  options: PlatonOption[];
  accounts: Account[];
  salesAgentKeywords: string;
}

export const defaultPlatonDetailForm = {
  platonId: 0,
  endUserName: '',
  salesAgentId: '0',
  plan: {
    planId: 0,
    planData: '',
    flowrateUpperLimit: 0,
    capacityUpperLimit: 0,
  },
  url: '',
  siteId: '',
  startDate: '',
  billingStartDate: '',
  endDate: '',
  closingDay: 24,
  salesMemo: '',
  server: {
    infrastructure: '',
    os: '',
    ipAddress: '',
    serverDetailUrl: '',
    installedPerson: '',
    installedDate: '',
    platonInstallDirectory: '',
  },
  options: [] as PlatonOption[],
  accounts: [] as Account[],
  salesAgentKeywords: '',
};

export interface Plan {
  planId: number;
  planData: string;
  flowrateUpperLimit: number;
  capacityUpperLimit: number;
}

export interface Server {
  infrastructure: string;
  os: string;
  ipAddress: string;
  serverDetailUrl: string;
  installedPerson: string;
  installedDate: string;
  platonInstallDirectory: string;
}
