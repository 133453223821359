


















































































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import Select from '@/components/molecules/Select.vue';
import TextField from '@/components/molecules/TextField.vue';
import { LibraDivision } from '@/domain/LibraContract/Types';
import { Plan } from '@/views/libra/LibraNewBasic/Form';
import {
  otegaruPlans,
  onpremisePlans,
  otegaruPlanTypes,
} from '@/domain/LibraContract/Plan';

@Component({
  components: {
    Select,
    TextField,
  },
  methods: mapActions('alertArea', ['success', 'fail']),
})
export default class LibraPlanSelector extends Vue {
  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  @Prop({ type: Number, required: true })
  private division!: number;

  @Prop({ type: Object, required: true })
  private value!: Plan;

  @Emit()
  // eslint-disable-next-line
  private input(val: Plan) {
    // Parent action
  }

  private get plans() {
    if (this.division === LibraDivision.Otegaru) {
      return otegaruPlans;
    }
    if (this.division === LibraDivision.OnPremise) {
      return onpremisePlans;
    }
    // undefined
    return [];
  }

  private get planType() {
    if (this.value.planId === 8 || this.value.planId === 9) {
      return otegaruPlanTypes;
    }
    return [];
  }

  private get flowrateUpperLimit() {
    return this.value.flowrateUpperLimit;
  }
  private get capacityUpperLimit() {
    return this.value.capacityUpperLimit;
  }
  private get otegaruPlanType() {
    return this.value.otegaruPlanType;
  }

  /**
   * ライフサイクル
   */

  /**
   * 操作
   */
  private async changePlan(val: number) {
    this.input({
      planId: val,
      planData: '',
      flowrateUpperLimit: 0,
      capacityUpperLimit: 0,
      otegaruPlanType: '',
    });
  }

  private async changePlanData(val: string) {
    this.input({
      planId: this.value.planId,
      planData: val,
      flowrateUpperLimit: this.flowrateUpperLimit,
      capacityUpperLimit: this.capacityUpperLimit,
      otegaruPlanType: this.otegaruPlanType,
    });
  }
  private async changeFlowrateData(val: number) {
    this.input({
      planId: this.value.planId,
      planData: this.value.planData,
      flowrateUpperLimit: val,
      capacityUpperLimit: this.value.capacityUpperLimit,
      otegaruPlanType: this.otegaruPlanType,
    });
  }

  private async changeCapacityData(val: number) {
    this.input({
      planId: this.value.planId,
      planData: this.value.planData,
      flowrateUpperLimit: this.value.flowrateUpperLimit,
      capacityUpperLimit: val,
      otegaruPlanType: this.otegaruPlanType,
    });
  }

  private async changePlanTypeNew(val: string) {
    this.input({
      planId: this.value.planId,
      planData: this.value.planData,
      flowrateUpperLimit: this.value.flowrateUpperLimit,
      capacityUpperLimit: this.value.capacityUpperLimit,
      otegaruPlanType: val,
    });
  }

}
