import { PlatonDivision } from '@/domain/PlatonContract/Types';

export interface Plan {
  planId: number;
  planData: string;
  flowrateUpperLimit: number;
  capacityUpperLimit: number;
}

export interface PlatonNewBasicForm {
  division: PlatonDivision;
  endUserId: string;
  salesAgentId: string;
  plan: Plan;
  url: string;
  siteId: string;
  startDate: string;
  billingStartDate: string;
  closingDay: number;
  initialVersion: string;
  salesMemo: string;
  endUserKeywords: string;
  salesAgentKeywords: string;
}

export const defaultPlatonNewBasicForm = {
  division: PlatonDivision.Otegaru,
  endUserId: '',
  salesAgentId: '0',
  plan: {
    planId: 0,
    planData: '',
    flowrateUpperLimit: 0,
    capacityUpperLimit: 0,
  },
  url: 'https://',
  siteId: '',
  startDate: '',
  billingStartDate: '',
  closingDay: 24,
  initialVersion: '',
  salesMemo: '',
  endUserKeywords: '',
  salesAgentKeywords: '',
};
