import { Customer } from '../Customer';
import { Plan } from './Plan';
import { Server } from './Server';
import { LibraOption } from './LibraOption';
import { LibraAccount } from './LibraAccount';
import { LibraVersion } from './LibraVersion';
import { LibraInstaller } from './LibraInstaller';

export interface LibraContract {
  libraId: number;
  endUserId: number;
  endUser: Customer;
  endUserName: string;
  salesAgentId: number;
  salesAgent: Customer | null;
  planId: number;
  plan: Plan;
  planData: string;
  url: string;
  siteId: string;
  startDate: string;
  billingStartDate: string;
  endDate: string | null;
  closingDay: number;
  timezone: string;
  salesMemo: string;
  server: Server | null;
  options: LibraOption[];
  accounts: LibraAccount[];
  version: LibraVersion;
  installer: LibraInstaller;
  flowrateUpperLimit: number;
  capacityUpperLimit: number;
  otegaruPlanType: string;
}

export const defaultLibraContract = {
  libraId: 0,
  endUserId: 0,
  endUser: new Customer(),
  endUserName: '',
  salesAgentId: 0,
  salesAgent: new Customer(),
  planId: 0,
  plan: new Plan(),
  planData: '',
  url: 'https://',
  siteId: '',
  startDate: '',
  billingStartDate: '',
  endDate: null,
  closingDay: 99,
  timezone: 'Asia/Tokyo',
  salesMemo: '',
  server: new Server(),
  options: [] as LibraOption[],
  accounts: [] as LibraAccount[],
  version: new LibraVersion(),
  installer: new LibraInstaller(),
  flowrateUpperLimit: 0,
  capacityUpperLimit: 0,
  otegaruPlanType: '',
};
