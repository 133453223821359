import { LibraDivision } from '@/domain/LibraContract/Types';

export interface Plan {
  planId: number;
  planData: string;
  flowrateUpperLimit: number;
  capacityUpperLimit: number;
  otegaruPlanType: string;
}

export interface Version {
  versionDivision: string;
  versionLabel: string;
}

export interface LibraNewBasicForm {
  division: LibraDivision;
  endUserId: string;
  salesAgentId: string;
  plan: Plan;
  url: string;
  siteId: string;
  startDate: string;
  billingStartDate: string;
  closingDay: number;
  version: Version;
  salesMemo: string;

  endUserKeywords: string;
  salesAgentKeywords: string;
}

export const defaultLibraNewBasicForm = {
  division: LibraDivision.Otegaru,
  endUserId: '',
  salesAgentId: '0',
  plan: {
    planId: 0,
    planData: '',
    flowrateUpperLimit: 0,
    capacityUpperLimit: 0,
    otegaruPlanType: '',
  },
  url: 'https://',
  siteId: '',
  startDate: '',
  billingStartDate: '',
  closingDay: 24,
  version: { versionDivision: 'V', versionLabel: '' },
  salesMemo: '',

  endUserKeywords: '',
  salesAgentKeywords: '',
};
